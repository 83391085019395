import React from "react";
import { Link } from "gatsby";

const AiTools = () => {
  return (
    <div className="lg:w-72 lg:mt-6 mt-8 border-[1.5px] border-[#0A2641] w-full p-6 rounded-lg flex flex-col gap-4 items-center">
      <span className="text-[#0A2641] font-bold text-xl font-primary">
        AI Tools
      </span>
      <p className="text-[#00173A] font-opensans text-center">
        Begin translations with confidence using the Tomedes Pre-Translation
        Toolkit
      </p>
      <Link
        to="/tools/pre-translation"
        className="bg-[#5B93FF] max-w-max mt-2 rounded-full py-3 px-8 text-sm font-opensans font-bold text-white"
      >
        Try Now
      </Link>
    </div>
  );
};

export default AiTools;

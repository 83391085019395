import React from "react";

import Social from "components/TranslatorHub/Social";
import { Link } from "gatsby";
import Form from "./TranslatorHubDetails/getForm";
import AiTools from "./AiTools";

function HubCategories({ data }) {
  return (
    <div className="md:ml-12 ml-0 mt-10 md:mt-0 px-4 lg:pl-0 lg:pr-2">
      <Form />
      <AiTools />
      <div className="line border-b border-gray mt-12"></div>
      <div>
        <h5 className="font-bold text-newGray text-xl mt-4">CATEGORIES</h5>
        <ul className="text-newGray">
          {data.map((link, index) => (
            <li
              className="text-new py-2 hover:text-newLightBlue cursor-pointer"
              key={index}
            >
              <Link to={`/translator-hub.php/category/${link}`}>{link}</Link>
            </li>
          ))}
        </ul>
      </div>
      <div className="line border-b border-gray mt-12"></div>
      <Social />
    </div>
  );
}
export default HubCategories;
